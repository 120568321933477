@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Bold.ttf) format('opentype');
  font-weight: bold;
}

html {
  background-color: #fff;
}
html,
body,
p,
textarea {
  font-family: 'Inter', 'sans-serif';
}
